import variables from "@root/constants/ajaxVariables";
import createObject from "./createObject";
import AjReplyJSON from "./AjReplyJSON";

export default function doAjRouteJSON(file, el_id, parstr, callback, el_id2, ajax_wd) {
  if (variables.ajax_now_blocked) {
    return false;
  }
  variables.ajax_now_blocked = true;
  variables.rout_callb = callback;
  variables.rout_el_id = el_id;
  variables.rout_el_id2 = el_id2;
  variables.ajax_wait_div = ajax_wd;

  if (el_id) {
    document.getElementById(el_id).innerHTML = "Завантаження...";
  }
  if (ajax_wd) {
    var jwd = document.getElementById(ajax_wd);
    if (jwd != null) {
      jwd.style.display = "block";
    }
  }
  if (variables.http == null) variables.http = createObject();
  variables.nocache = Math.random();
  variables.http.open(
    "get",
    document.location.origin +
    "/ajaxrout.php?&JSON=1&file=" +
    file +
    parstr +
    "&nocache=" +
    variables.nocache
  );
  variables.http.onreadystatechange = AjReplyJSON;
  variables.http.send(null);
}
