let variables = {
  http: null,
  rout_el_id: "",
  rout_el_id2: "",
  rout_el_cls: "",
  rout_el_cls2: "",
  rout_callb: "",
  ajax_wait_div: "",
  nocache: "",
  ajax_now_blocked: false
};

export default variables;
